<template>
  <GoogleAdSenseFeed
    classNames="sorry-hochy-deneg"
    slotId="1428037674"
    layoutKey="-6x+ef+1v-2l-b"
    v-if="index % 4 == 0"
  />
  <div class="col mb-4">
    <div class="card pj-card">
      <div class="card-body">
        <h4 class="card-title">
          <span class="badge pj-badge-primary">{{ promocode.code }}</span>
        </h4>
        <h6 class="card-subtitle mb-2 text-muted" v-if="promocode.conditions">
          {{ promocode["conditions"][0]["criteria"] }}
        </h6>
        <div class="card-text mb-2">
          <template v-if="promocode.rewards">
            <template
              v-if="promocode['rewards'][0]['reward'] == 'order_discount'"
            >
              {{ promocode["rewards"][0]["message"] }}
            </template>
            <template v-if="promocode['rewards'][0]['reward'] == 'good'">
              {{ promocode["rewards"][0]["message"] }}:<br />
              <template
                v-for="good in promocode['rewards'][0]['goods']"
                :key="good.id"
              >
                <template v-if="good.category == 'pizza'">
                  - <i>{{ good["name"] }}</i>
                  <template v-if="good.variations.length > 1">
                    ({{ good["variations"][0]["diameter"] }} см)<br />
                  </template>
                  <template v-else>
                    ({{ good["variations"][0]["diameter"] }} см,
                    {{
                      good["variations"][0]["dough"].slice(0, 4).toLowerCase()
                    }}.)<br />
                  </template>
                </template>
                <template v-else-if="good.category == 'zakuski'">
                  -
                  <i>
                    {{ good["name"] }}
                    ({{ good["variations"][0]["diameter"] }})
                  </i>
                  <br />
                </template>
                <template v-else>
                  -
                  <i>
                    {{ good["name"] }}
                  </i>
                  <br />
                </template>
              </template>
            </template>
          </template>
        </div>
        <template v-if="promocode.additional_info">
          <p class="text-muted">{{ promocode["short_info"] }}</p>
          <small class="text-muted"
            >Дополнительная информация:
            {{ promocode["additional_info"] }}</small
          >
        </template>
        <div class="card-text">
          <small class="text-muted"
            >добавлен {{ getDateDDMMYYYY(promocode["creation_date"].$date) }}
            <template
              v-if="
                promocode['creation_date'].$date !=
                  promocode['update_date'].$date
              "
            >
              (обновлен {{ getDateDDMMYYYY(promocode["update_date"].$date) }})
            </template>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import GoogleAdSenseFeed from "@/components/ads/GoogleAdSenseFeed.vue";

export default {
  name: "Promocode card",
  props: {
    promocode: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    }
  },
  setup() {
    return {
      GoogleAdSenseFeed
    };
  },
  methods: {
    getDateDDMMYYYY(value) {
      return dayjs(value).format("DD.MM.YY");
    }
  }
};
</script>

<style lang="scss" scoped>
.pj-badge-primary {
  background: $primary-light;
  color: white;
}

@media (prefers-color-scheme: dark) {
  .pj-card {
    background-color: #232324;
    color: white;
  }
  .pj-badge-primary {
    background: $primary-dark;
  }
}
</style>
