<template>
  <div class="col mb-4">
    <div class="card pj-card">
      <div :class="classNames">
        <ins
          class="adsbygoogle"
          :style="{ display: 'block' }"
          :data-ad-client="googleAdId"
          :data-ad-slot="slotId"
          :data-ad-layout-key="layoutKey"
          data-ad-format="fluid"
          data-full-width-responsive="true"
        ></ins>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
  name: "GoogleAdSenseFeed",
  props: {
    classNames: String,
    slotId: String,
    layoutKey: String
  },

  setup() {
    const googleInit = ref(null);
    const googleAdId = ref("ca-pub-6827481669361525");
    onMounted(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    });

    return {
      googleInit,
      googleAdId
    };
  }
};
</script>

<style lang="scss" scoped>
@media (prefers-color-scheme: dark) {
  .pj-card {
    background-color: #232324;
  }
}
</style>
